import { cn } from "@/lib/utils/cn";
import React, { ReactNode } from "react";

interface IH4Props {
  children: ReactNode;
  className?: string;
}

const H4 = ({ children, className }: IH4Props): ReactNode => (
  <h4 className={cn("my-6 text-2xl font-bold", className ? className : "")}>
    {children}
  </h4>
);

export default H4;
