import { cn } from "@/lib/utils/cn";
import React, { ReactNode } from "react";

interface IH2Props {
  children: ReactNode;
  className?: string;
}

const H2 = ({ children, className }: IH2Props) => (
  <h2
    className={cn(
      "my-6 text-5xl md:text-6xl font-bold",
      className ? className : ""
    )}
  >
    {children}
  </h2>
);

export default H2;
